:root {
  --dominant-color: #f7f7f7;
  --primary-color: #f43030;
  --accent-color: #3030f4;

  --white: #ffffff;
  --neutral: #223e58;
  --background-grey: #fafafa;
  --bg-success: #dcf1e2;
  --text-success: #155724;

  --color-success-light: #1aa541;

  --status-bg-success: #146c43;

  --status-bg-warning: #ffcd37;
  --status-bg-blue: #3ad4f3;

  --status-bg-red: #ff616b;
  --status-border-red: #b32b38;

  --control-border-color: #dce0e3;
  --color-error: #c60d01;
}

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;500;800&family=Montserrat:wght@400;800;900&display=swap");

.bg-primary {
  background-color: var(--primary-color);
}

.bg-grey-custom {
  background-color: var(--background-grey);
}

.bg-success {
  background-color: var(--bg-success);
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-secondary {
  color: var(--primary-color);
  font-weight: bold;
}

.btnGoBack{
  background: #FFF;
  border-radius: 10px;
  color: #F43030;
  font-weight: 700;
}

.rtp-amount {
  color: var(--primary-color);
  font-weight: bold;
  font-size: small;
}

.accent-primary {
  accent-color: var(--primary-color);
}

.status {
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid black;
}

.status-bg-green {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.status-bg-orange {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.status-bg-red {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.status-bg-grey {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.status-bg-blue {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.btn {
  border-radius: 10px;
  min-width: 80px;
  box-sizing: border-box;
  padding: 8px 20px;
  cursor: pointer;
}

.btn-small {
  border-radius: 5px;

  box-sizing: border-box;
  padding: 3px 20px;
}

.btn-glow {
  box-shadow: 0px 0px 8px #f43030;
}

.btn-primary:disabled {
  background-color: #ccc;
}

.date-picker{
  color: var(--primary-color);
  width: 90px;
}

.date-picker:disabled{
  color: #aaa9a9;
}

.text-Montserrat {
  font-family: "Montserrat", sans-serif;
}

.text-primary {
  color: var(--primary-color);
}

.text-custom-sm {
  font-size: 0.6rem;
}

.text-neutral {
  color: var(--neutral);
}

.text-error {
  color: var(--color-error);
}

.border-error {
  border-color: var(--color-error) !important;
}

.border-success {
  border-color: var(--color-success-light) !important;
}

.text-success {
  color: var(--text-success);
}

.text-success-light {
  color: var(--text-success-light);
}

.input-text {
  background-color: var(--white); 
  border: 1px solid var(--control-border-color);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px;
}

body {
  background-color: var(--dominant-color);
  font-family: "Manrope", sans-serif;
  color: var(--neutral);
  font-size: 9pt;
}

.box-container {
  background-color: var(--white); 
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

@media only screen and (max-width: 768px) {
  .box-container {
    width: 98%;
    justify-self: center;
    box-shadow: 0px 0px 0px;
    border-radius: 0px;
    /* background-color: #3ad4f3; */
  }
  body{
    background-color: var(--white); 
  }
  
} 

@media only screen and (min-width: 1024px) {

  .box-container {
    
  }
} 

#tblCommons th {
  padding: 10px;
  /* text-align: left; */
}

#tblCommons > tbody td {
  padding: 5px 10px;
}

#tblCommons tfoot td {
  padding: 20px;
}

#tblCommons tr {
  border: 1px solid #f3f3f3;
}
