#tblTopupRequests th {
  padding: 10px;
  /* text-align: left; */
}

#tblTopupRequests > tbody td {
  padding: 5px 10px;
}

#tblTopupRequests tfoot td {
  padding: 20px;
}

#tblTopupRequests tr {
  border: 1px solid #f3f3f3;
}

#details > div.flex {
  padding-top: 15px;
}

#details > div span:nth-child(2) {
  color: #008500;
}

.status {
  border: 1px solid;
}

.status-pending {
  color: #f03738;
  border-color: #f03738;
  background-color: #fdebeb;
}

.status-processing {
  color: #3b82f6;
  border-color: #93c5fd;
  background-color: #eff6ff;
}

.status-done {
  color: #fff;
  border-color: #1aa541;
  background-color: #1aa541;
}

.status-cancelled {
  color: #f03738;
  border-color: #f03738;
  background-color: #fdebeb;
}

.status-onhold {
  color: #f3bb1c;
  border-color: #f3bb1c;
  background-color: #fef8e8;
}

.request-detail-animation {
  margin-right: 0px !important;
  transition: 250ms;
}

.detail-container {
  width: 400px;
  margin-right: -400px;
  transition: 250ms;
}
