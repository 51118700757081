/* #tblTopupRequests thead th {
  text-align: left;
} */

#tblPrintTopupRequests > tbody td {
  padding: 5px 10px;
}

@media print {
  @page {
    /* margin: 10px; */
  }
  body {
    /* margin: 1.6cm; */
  }
}
