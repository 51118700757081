.menuItem > div:hover {
  accent-color: #f43030;
  color: #f43030;
}

.menuItem > div {
  margin-left: 4px;
}

.sideBarButton:hover {
  accent-color: #f43030;
  color: #f43030;
}

.menuItem.selected > div {
  accent-color: #f43030;
  color: #f43030;
  border-left: 4px solid #f43030;
  margin-left: 0px;
}

.ActiveIndicator {
  /* bg-primary rounded-full */
}

.imgMenu path {
  fill: #f43030;
}

.sidebar {
  height: 100vh;
  transition: 500ms;
  display: flex;
  flex-direction: column;
  background-color: #fafbfb;
  transition: 200ms;
  max-width: 100%;
}

.hide-delay {
  transition-delay: visibility 200ms, height 0ms;
  visibility: hidden;
  height: 0px;
}

.sidebar.collapse {
  max-width: 50px;
  overflow: hidden;
  transition: 200ms;
}
