#frmEditPaymentMethod > div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  margin-top: 10px;
}

#frmEditPaymentMethod > span {
  color: red;
  font-weight: 600;
  font-size: 0.6rem;
}
