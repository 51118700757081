.modalBackground{
    position:absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer{
    width: fit-content;
    height: fit-content;
    max-width: 400px;
}
.btnCallToAction{
    background: #F43030;
    border-radius: 8px;
    color: #FFF;
}
.btnGoBack{
    background: #FFF;
    border-radius: 8px;
    color: #F43030;
    font-weight: 700;
}
.btnSecondary{
    border-radius: 8px;
    color: #223E58;
    font-weight: 700;
    padding: 10px 16px;
}