#tblJournalEntries th {
  padding: 10px;
}

#tblJournalEntries > tbody td {
  padding: 5px 10px;
}

#tblJournalEntries tfoot td {
  padding: 20px;
}

#tblJournalEntries tr {
  border: 1px solid #f3f3f3;
}

/* @page {
  size: auto;
  margin: 20mm;
} */



