.modalBackground{
    position:absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer{
    width: fit-content;
    height: fit-content;
    max-width: 400px;
}