#journal-entry-table th {
  padding: 10px;
  text-align: left;
}

#journal-entry-table tr {
  border: 1px solid #f3f3f3;
}

#journal-entry-table td {
  padding: 10px;
}
